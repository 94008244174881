import React from 'react';
import { Title, Text } from '../ui/index';
import Content from '../components/Content';
import { useTranslation } from 'react-i18next';
import CountryDescriptionInformations from './CountryDescriptionInformations';

const CountryDescription = ({ country }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Title destination>
            {t('utils.country.description.titleDesc', {
            destination: country.title
          })}
        </Title>

        <CountryDescriptionInformations country={country} />

        <Text>
          <Content html={country.description} />
        </Text>
      </div>

      {country?.bestPeriod && (
        <>
          <Title destination>
            {t('utils.country.description.climat')}
          </Title>
          <Text>
            <Content html={country.bestPeriod} />
          </Text>
        </>
      )}

      {country?.bestInterestPoint && (
        <>
          <Title destination>
            {t('country.description.unavoidable.title')}
          </Title>
          <Text>
            <Content html={country.bestInterestPoint} />
          </Text>
        </>
      )}

      {country?.unescoPoint && (
        <>
          <Title destination>
            {t('utils.country.description.unesco')}
          </Title>
          <Text>
            <Content html={country.unescoPoint} />
          </Text>
        </>
      )}
    </>
  );
};

export default CountryDescription;
