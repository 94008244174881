import React from 'react';
import { Text  } from 'ui';
import Content from 'components/Content';


const CountryBudget = ({ country }) => {
  return (
    <>
      <Text>
        <Content html={country.expense} />
      </Text>
    </>
  );
};

export default CountryBudget;
