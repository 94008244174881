import React from 'react';
import { media, Text } from '../ui';
import Content from './Content';
import { get } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CountryInfo = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};

  ${media.tablet`
      grid-template-columns: repeat(4, 1fr);
    `};

  ${media.tablet`
    grid-gap: ${({ theme }) => theme.spacing()};
    `}

  ${media.medium`
    grid-gap: ${({ theme }) => theme.spacing()};
    `}

  ${media.desktop`
    grid-gap: ${({ theme }) => theme.spacing()};
    `}
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const Subtitle = styled.h3`
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 800;
`;
const ImageContainer = styled.div`
  > img {
    margin-top: 9px !important;
  }
`;
const InfoContent = styled.div`
  margin-left: 8px;

  > span > div > p {
    font-size: 12px !important;
  }
`;
const CountryDescriptionInformations = ({country}) => {
  const { t } = useTranslation();
  return (
    <CountryInfo>
      {get(country, 'plugs', '') && (
        <InfoItem>
          <ImageContainer>
            <img
              className="h-5 w-5"
              src={require('../assets/svg/prises.svg')}
              alt=""
            />
          </ImageContainer>
          <InfoContent>
            <Subtitle>{t('utils.country.description.outlet')}</Subtitle>
            <Text>
              <Content html={country.plugs} />
            </Text>
          </InfoContent>
        </InfoItem>
      )}

      {country.phoneCode && (
        <InfoItem>
          <ImageContainer>
            <img
              className="h-5 w-5 transform"
              src={require('../assets/svg/indicateur.svg')}
              alt=""
            />
          </ImageContainer>
          <InfoContent>
            <Subtitle>{t('utils.country.description.tel')}</Subtitle>
            <Text>
              <Content html={`+${country.phoneCode}`} />
            </Text>
          </InfoContent>
        </InfoItem>
      )}

      {country.timeZone && (
        <InfoItem>
          <ImageContainer>
            <img
              className="h-5 w-5 transform"
              src={require('../assets/svg/fuseaux.svg')}
              alt=""
            />
          </ImageContainer>
          <InfoContent>
            <Subtitle>{t('utils.country.description.time')}</Subtitle>
            <Text>
              <Content html={country.timeZone} />
            </Text>
          </InfoContent>
        </InfoItem>
      )}

      {country.capital && (
        <InfoItem>
          <ImageContainer>
            <img
              className="h-5 w-5"
              src={require('../assets/svg/capital.svg')}
              alt=""
            />
          </ImageContainer>
          <InfoContent>
            <Subtitle>{t('utils.country.description.capital')}</Subtitle>
            <Text>
              <Content html={country.capital} />
            </Text>
          </InfoContent>
        </InfoItem>
      )}

    </CountryInfo>
  )
}

export default CountryDescriptionInformations;
