import React from 'react';
import styled from 'styled-components';
import IcCreateNewCompte from '../../assets/images/icons/IcCreateNewCompte';
import IcPersonal from '../../assets/images/icons/IcPersonal';
import IcCollabo from '../../assets/images/icons/IcCollabo';
import IcCreateNewProfile from '../../assets/images/icons/IcCreateNewProfile';
import {Text} from '../StepCards'
import { useTranslation } from 'react-i18next';
import StepCards from './StepCards';
import { Grid as RawGrid, Cell as RawCell,media,HomeSection } from 'ui';

export const HowItWorksTitleContainer = styled.div`
  width:100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: 30px;
  ${media.medium`
    width:auto;
    flex:1;
    margin-bottom:0px;
 `}
 > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }
`

const Grid = styled(RawGrid)`
  flex:2;
  margin-top:0px;
  margin-left:0px;
  grid-row-gap: ${({ theme }) => theme.spacing(3)};
  
`

const Cell = styled(RawCell)`
  padding-top: 0px;
  padding-left: 0px;
  ${media.medium`
    padding-top: ${({ theme }) => theme.spacing()};
    padding-left: ${({ theme }) => theme.spacing()};
 `}
`

export const HowItWorksTitle = styled.div`
  color: ${({ theme }) => theme.text};
  text-align:left;

  font: normal normal bold 24px Muli;
  ${media.tablet`
    font: normal normal bold 32px Muli;
 `}
  ${media.medium`
    font: normal normal bold 40px/41px Muli;
 `}
`

export const FlexBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  ${media.medium`
    > * {
      margin-top: 0;

      + * {
        margin-top: 0;
      }
    }
  `}
`;

const HowItWorks = () => {
  const {t} = useTranslation();
  return (
    <HomeSection centered>
      <FlexBlock>
        <HowItWorksTitleContainer>
          <HowItWorksTitle>
            {t('newHowitWorks.title')}
          </HowItWorksTitle>
          <Text>
            {t('newHowitWorks.description')}
          </Text>
        </HowItWorksTitleContainer>
        <Grid variant="home">
          <Cell sizes={{ default: 1 }}>
            <Grid>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                <StepCards
                  icon={
                    <IcCreateNewCompte
                      style={{
                        color: '#73C992',
                        display: 'flex',
                        marginLeft: '-15px',
                      }}
                    />
                  }
                  title={
                    <div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '23%',
                          height: '2px',
                          backgroundColor: '#73C992',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '77%',
                          height: '2px',
                          backgroundColor: 'silver',
                        }}
                      ></div>
                      {t('newHowitWorks.newcompt.title')}
                    </div>
                  }
                  text={t('newHowitWorks.newcompt.subtitle')}
                />
              </Cell>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                <StepCards
                  icon={
                    <IcPersonal
                      style={{ color: '#73C992', display: 'flex' }}
                    />
                  }
                  title={
                    <div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '50%',
                          height: '2px',
                          backgroundColor: '#73C992',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '50%',
                          height: '2px',
                          backgroundColor: 'silver',
                        }}
                      ></div>
                      {t('newHowitWorks.personalized.title')}
                    </div>
                  }
                  text={t('newHowitWorks.personalized.subtitle')}
                />
              </Cell>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                <StepCards
                  icon={
                    <IcCollabo
                      style={{ color: '#73C992', display: 'flex' }}
                    />
                  }
                  title={
                    <div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '85%',
                          height: '2px',
                          backgroundColor: '#73C992',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '15%',
                          height: '2px',
                          backgroundColor: 'silver',
                        }}
                      ></div>
                      {t('newHowitWorks.colloborative.title')}
                    </div>
                  }
                  text={t('newHowitWorks.colloborative.subtitle')}
                />
              </Cell>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                <StepCards
                  icon={
                    <IcCreateNewProfile
                      style={{ color: '#73C992', display: 'flex' }}
                    />
                  }
                  title={
                    <div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          height: '2px',
                          backgroundColor: '#73C992',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '0%',
                          height: '2px',
                          backgroundColor: 'silver',
                        }}
                      ></div>
                      {t('newHowitWorks.sustainable.title')}
                    </div>
                  }
                  text={t('newHowitWorks.sustainable.subtitle')}
                />
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </FlexBlock>
    </HomeSection>
  );
};

export default HowItWorks;
