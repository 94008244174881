import React from 'react';
import styled from 'styled-components';

const WrapperCard = styled.div`
  max-width: 298px;
  padding: ${({ theme }) => theme.spacing()};
  text-align: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }
`;

const StepName = styled.div`
  color: ${({ theme }) => theme.text};
  text-align:left;
  font: normal normal bold 20px/28px Muli;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }) => theme.text};
`;

const CardText= styled(Text)`
  width:300px;
`

const StepNumber = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 28px;
  font-weight: 700;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 100%;
  margin: auto;
  width: 54px;
  height: 54px;
  align-items: center;
`;

const StepCards = ({ title, text, number, icon }) => (
  <WrapperCard>
    {number && (
      <StepNumber>
        <div>{number}</div>
      </StepNumber>
    )}
    {icon && <div>{icon}</div>}
    <StepName>{title}</StepName>
    <CardText>{text}</CardText>
  </WrapperCard>
);

export default StepCards;
