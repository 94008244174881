import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'ui/index';
import { useTranslation } from 'react-i18next';
import CountryBudget from 'components/CountryBudget';
import CountryDoAndDont from 'components/CountryDoAndDont';
import CountryHealth from 'components/CountryHealth';
import CountrySuitcase from 'components/CountrySuitcase';
import CountryTransports from 'components/CountryTransports';
import CountryDescription from 'components/CountryDescription';
import CountryVisa from 'components/CountryVisa';
import { Container } from 'ui/containers';
import DiscoverDestinations from 'components/DiscoverDestinations.js'
import {
    LeftTabContainer,
    RightTabContainer,
    SplitTabContainer,
  } from 'ui/tabs';
import {TabItem, TabContent, MobileMenuContainer, Button } from  '../templates/sustainable-agreements';
import { Select } from '../components/Select';
import Travelogue from 'components/Travelogue';

const TravelogueContainer = styled.div`
    position: relative;
    z-index: 0 !important;
    background-color: #F7F7F7;
    ${media.mobile`
      z-index: 1 !important;
  `}
`

const CountryInformation = ({ country, cities }) => {
  const { t } = useTranslation();
  const [contentSelected, setContentSelected] = useState(0);
  const [stickyClass, setStickyClass] = useState('');

  const handleClick = (index) => {
    setContentSelected(index)
  };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 400,
      behavior: 'smooth',
    });
  }
    
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('sticky-nav-sustainable') : setStickyClass('');
    }
  };

  const tabElement = [
    { label: t('country.description.title') },
    { label: t('country.visa.titre') },
    { label: t('country.transport.title')},
    { label: t('country.budget.title') },
    { label: t('country.health.title') },
    { label: t('country.doDont.titre') },
    { label: t('country.suitcase.title') },
  ].filter(Boolean);

  const tabContent = [
    <CountryDescription
      country={country}
      articles={country.articles}
      cities={cities}
    />,
    <CountryVisa country={country} />,
    <CountryTransports country={country} />,
    <CountryBudget country={country} />,
    <CountryHealth country={country} />,
    <CountryDoAndDont country={country} />,
    <CountrySuitcase country={country} />,
  ].filter(Boolean);

  return (
    <>
		<MobileMenuContainer className={stickyClass} isDestination>
			<Select
				name="menu"
				onChange={input => {
					handleClick(input.value, 'menu');
          ScrollToTop()
				}}
				options={[
					...(tabElement || []).map((result, index) => ({
						label: result.label,
						value: index,
					})),
				]}
				value={contentSelected}
				placeholder={tabElement[contentSelected].label}
				variant={'menu'}
				type={'article'}
			/>
    </MobileMenuContainer>
    <Container>
       <SplitTabContainer  sustainableContainer destination>
        <LeftTabContainer sideMenuSustainable>
        {tabElement.map((tab, index) => (
            <TabItem isActive={contentSelected === index} 
            onClick={e => {
                ScrollToTop()
                e.preventDefault();
                setContentSelected(index);
            }}
            >
              <Button
                  variant="tab"
                  isActive={contentSelected === index}
                  onClick={e => {
                  ScrollToTop()
                  e.preventDefault();
                  setContentSelected(index);
                  }}
              >
                  {tab.label}
              </Button>
            </TabItem>
        ))}
					</LeftTabContainer>
					<RightTabContainer menuContent>
					<TabContent style={{ outline: 'none', marginTop: 20 }}>
							{tabContent[contentSelected]}
					</TabContent>
					</RightTabContainer>
			</SplitTabContainer>
     {cities.length !== 0 && <DiscoverDestinations cities={cities} country={country}></DiscoverDestinations> }
    </Container>

    {country.articles.length !== 0 && 
			<TravelogueContainer>
					<Travelogue articles={country.articles.slice(0, )}/>
			</TravelogueContainer>
    }

    </>
  );
};

export default CountryInformation;
