import React from 'react';
import { Title, Text } from 'ui';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';

const CountryTransports = ({ country }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title destination>{`${t('utils.country.description.titleTransport')} ${
        country.title
      }`}</Title>
      <Text>
        <Content html={country.accessCountryTransports} />
      </Text>
      <Title destination>
        {`${t('country.transport.move')} ${country.title} ?`}
      </Title>
      <Text>
        <Content html={country.domesticTransports} />
      </Text>
    </>
  );
};

export default CountryTransports;
