import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import MyTabs from 'components/MyTabs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import HeroHeader from '../components/HeroHeader';
import CountryAccommodation from '../components/CountryAccommodation';
import CountryInformation from '../components/CountryInformation';
import { media} from 'ui';

const CountryContainer = styled.div`
    width: 100%;
    ${media.mobile`
     margin-top: 40px;
  `}
  `;

const Country = ({
  data: {
    country,
    allCity: { nodes: cities },
  },
}) => {
  const { t } = useTranslation();

  useDataLayerBuilder(({ country }) => ({
    pageType: 'country',
    searchCountry: country.title,
    searchCountryId: country.originalId,
  }));

  const tabElements = [
    { label: t('country.tabTitle1') },
    { label: t('country.tabTitle2') }
  ];
  const tabContents = [
    <CountryAccommodation
      country={country}
      cities = {cities} 
    />,
    <CountryInformation 
      country={country} 
      cities = {cities} 
    />
  ]


  const getBanner = () => {
    var num = Math.floor(Math.random() * (country.pictures.length));
    const imageUrl = country.picture ? country.picture?.imagePath : country.pictures[num]?.mediumUrl;
    return imageUrl;
  } 

  const [imageBanner, setimageBanner] = useState(getBanner());

  
  useEffect(() => {
    setimageBanner(getBanner())
  },[country]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <SEO title={country.title} />
      <HeroHeader
        title={t('country.bannerTitle')}
        subtitle={country.title}
        defaultPicture={!country.picture} 
        image={imageBanner}
        country
      />
      <CountryContainer>
        <MyTabs
          tabs={tabElements}
          contents={tabContents}
          variant="search"
          sustainable = {true}
          destination  = {true}
        />  
      </CountryContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($language: String!, $id: String!) {
    country(language: { eq: $language }, id: { eq: $id }) {
      id
      slug
      description
      originalId
      title
      subtitle
      lat
      lon
      phoneCode
      alpha2
      status
      timeZone
      capital
      expense
      health
      visa
      accessCountryTransports
      domesticTransports
      doAndDont
      suitcase
      vocabulary
      anecdotes
      numberHotels
      unescoPoint
      bestInterestPoint
      bestPeriod
      spokenLanguages
      flyingTime
      plugs
      expressions
      picture {
          imagePath
          smallImagePath
        }
      attachments {
        id
        mediumUrl
        largeUrl
        smallUrl
      }
      articles  {
        id
        displayName
        publishedAt
        excerpt
        slug
        picture {
          imagePath
        }
        thematic {
          displayName
        }
      }
      hotels(limit: 11) {
        totalCount
        nodes {
          id
          displayName
          slug
          stars
          sustainable {
            level
          }
          city {
            slug
            title
            country {
              title
              slug
            }
          }
          stars
          styles {
            displayName
          }
          primaryAttachment {
            smallUrl
            mediumUrl
          }
          secondaryAttachments {
            mediumUrl
            smallUrl
          }
        }
      }
      pictures(limit: 100, randomize: true) {
        id
        mediumUrl
        largeUrl
        smallUrl
        displayName
      }
    }
    allCity(
      filter: {
        country: { id: { eq: $id } }
        attachments: { elemMatch: { id: { ne: "" } } }
        available: { eq: true }
      }
    ) {
      nodes {
        id
        title
        slug
        country {
          title
          slug
          id
        }
        hotels{
          totalCount
        }
        attachments {
          id
          mediumUrl
          largeUrl
          smallUrl
        }
      }
    }
  }
`;

export default Country;
