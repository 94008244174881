import React from 'react';
import { Text } from 'ui';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';
import { Title } from '../ui/index';

const CountrySuitcase = ({ country }) => {
  const { t } = useTranslation()

  return (
    <>
      <Title destination>
          {t('utils.country.description.titleVisa', {
          destination: country.title
        })}
      </Title>
      <Text>
        <Content html={country.visa} />
      </Text>
    </>
  );
};

export default CountrySuitcase;
