import React from 'react';
import { Text, Title  } from 'ui';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';

const CountrySuitcase = ({ country }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title destination>{t('country.suitcase.needed')}</Title>
      <Text>
        <Content html={country.suitcase} />
      </Text>
    </>
  );
};

export default CountrySuitcase;
