import React from 'react';
import {  Text, Title  } from 'ui';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';

const CountryHealth = ({ country }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title destination>{t('utils.needed.essential')}</Title>
      <Text>
        <Content html={country.health} />
      </Text>
    </>
  );
};

export default CountryHealth;
