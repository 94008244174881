import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { CityCard } from 'ui/cards';
import PageContext from 'contexts/page';
import { useMedia } from 'react-use';
import RightArrowSlider from '../assets/images/RightArrowSlider.png';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.png';
import Slider from 'react-slick';
import { media } from 'ui';
import { get } from 'lodash';

const Carousel = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media.desktop`
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 10%;
`}

  ${media.tabletprod`
  max-width : 500px;
  width: 90%;
 `}

  ${media.tablet`
    max-width : 500px;
    width: 90%;
  `}

    ${media.mobile`
      max-width : 295px;
      width: 90%;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    `}

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 63px;
      height: auto;
      top: -48px;
      z-index: 30;
      margin: ${({ theme }) => theme.spacing(-1)};
    }

    .slick-next:before,
    .slick-prev:before {
      content: none;
    }
    
    .slick-next:after {
      content: none;
    }

    .slick-next {
      right: 100px;
    }

    .slick-prev {
      left: 70%;
    }
    ${media.tablet`
        slick-next {
          right: 100px;
        }
        .slick-prev {
          left: 72%;
        }
    `}

     ${media.tabletprod`
        slick-next {
          right: 100px;
        }
        .slick-prev {
          left: 75%;
        }
    `}

    ${media.mobile`
    
      .slick-next,
      .slick-prev {
          width: 45px;
          top: -25px;
        }

      .slick-prev {
        left: 65%;
      }
      
      .slick-next {
        right: 10%;
      }
    `}
  }
`;

const CityLink = styled(Link)`
  text-decoration: none;

  ${media.desktop`
    width: 90% !important;
      `}
  ${media.tabletprod`
      width: 90% !important;
        `}
`;

const CityCards = ({ cities, externalArrows, setSlider }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const isMobile = useMedia('(max-width: 750px)');
  const isTablet = useMedia('(min-width: 750px) and (max-width: 1000px)');

  const slidesToShow =
    isMobile || isTablet
      ? 1
      : Math.min(cities.length, 3);

  const slidesToScroll = isMobile || isTablet ? 1 : Math.min(cities.length,3);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#FFA24B',
          padding: '10px',
          borderRadius: '17px',
        }}
        onClick={onClick}
      >
        <img src={RightArrowSlider} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: '#FFA24B',
          padding: '10px',
          borderRadius: '17px',
          zIndex: '10',
        }}
        onClick={onClick}
      >
        <img src={LeftArrowSlider} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    initialSlide: 0,
    autoplay: false,
    arrows: true,
    nextArrow: !externalArrows ? <SampleNextArrow /> :undefined,
    prevArrow: !externalArrows ? <SamplePrevArrow /> :undefined,
  };

    return (
      <Carousel>
        <Slider {...settings} ref={slider => {if (setSlider)setSlider(slider) }} style={{ marginLeft: -16, marginRight: -16 }}>
        	{(cities || []).map((city, index) => (
						<CityLink key={index} to={getLanguagePrefix(
							`${get(city, 'country.slug')}/${get(city, 'slug')}`,
							)}>
                {city.hotels.totalCount >= 1 ? <CityCard {...city}/> : '' } 
							
						</CityLink>
						),
					)}
        </Slider>
      </Carousel>
    );
};

export default CityCards;
