import React, { useContext, useState,useEffect } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import {  GridContainer } from '../ui/containers';
import { useTranslation } from 'react-i18next';
import PageContext from '../contexts/page';
import {  Button,  SpinnerContainer } from 'ui';
import Spinner from 'components/Spinner';
import { HotelCard } from 'ui/cards';
import { CityCard } from 'ui/cards';

const ButtonContainer = styled.div`
	margin-top: 70px;
	margin-bottom: 60px;
	> a {
		width: 227px;
    height: 55px;
	}
`;
const Container = styled.div`
	width: 90%;
	margin-left: 5%;
	margin-top: 25px;
`;

const CountryAccommodation = ({ country, cities }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

var num = Math.floor(Math.random() * (cities?.length));
const city = cities[num];

 useEffect(() => {
      if(country.hotels.nodes.length){
        setIsLoading(false)
      }    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
		<Container>	
			{isLoading ? (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				) : (
				<>
				<GridContainer variant="explore">

					{(country.hotels.nodes || []).map((hotel, index) => (
						<>
						{index === 5 ?
							<CityCard {...city} isRandom/> :
							<HotelCard
								{...hotel}
								key={hotel.id}
								openInNewTab
								openEngagement
								destination
							/>
						}
						</>
					))}
				</GridContainer>
				<ButtonContainer>
					<Button
						hotels
						variant="carnet"
						as={Link}
						to={`${getLanguagePrefix(t('url.hotels'))}?countryId=${
							country.originalId
						}`} 	              >
						{t('country.description.hotel.button1')}
					</Button>
				</ButtonContainer>
				</>
					)
				}
		</Container>    
  );
};

export default CountryAccommodation;
