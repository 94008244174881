import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={60} height={60} viewBox="0 0 20 20" {...props}>
      <g
        transform="translate(1 4)"
        stroke="#73c992"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M4.86 13.91H1.62a1.08 1.08 0 01-1.08-1.08V1.62c0-.596.484-1.08 1.08-1.08h8.12c.596 0 1.08.484 1.08 1.08v4.86"
          strokeWidth={1.0}
        />
        <circle strokeWidth={1.08} cx={6.48} cy={4.59} r={1.89} />
        <path
          d="M7.932 7.357A3.273 3.273 0 006.48 7.02a3.23 3.23 0 00-3.041 2.16 3.264 3.264 0 00-.19 1.08M3.24 10.26h4.32"
          strokeWidth={1}
        />
        <g strokeWidth={0.96}>
          <path d="M13.03 13.043a.96.96 0 01-1.358 0l-1.357-1.358a.96.96 0 010-1.357L18.8 1.843a1.92 1.92 0 012.716 2.714l-8.486 8.486zM18.8 1.843a1.92 1.92 0 00-2.716 0l-2.036 2.036M16.424 4.218l2.715 2.716" />
          <path d="M11.002 12.372a1.482 1.482 0 01-.041 2.079c-.58.58-3.283 1.229-3.283 1.229s.667-2.688 1.246-3.264c.565-.58 1.49-.6 2.078-.044h0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
