import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CityCards from 'components/CityCards';
import { useMedia } from 'react-use';
import { Text as RawText } from 'components/StepCards';
import {HowItWorksTitleContainer as RawTitleContainer,HowItWorksTitle as Title} from 'components/Home/HowItWorks'
import { media , Grid, Cell} from 'ui';
import RightArrowSlider from '../assets/images/RightArrowSlider.png';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.png';

const ContainerSection = styled.div`
  position: relative;
  background-color: #fff;
  height: 400px;
  margin-top: 80px;
  padding-top: 50px;
  ${media.desktop`
      margin-bottom: -70px;
  `}
`;

export const TitleContainer = styled(RawTitleContainer)`
  display: flex;
  padding-top: 0px;
  justify-content: space-around;
  ${media.desktop`
  justify-content: flex-start;
  `}
`;

export const Text = styled(RawText)`
  margin-top: ${({ theme }) => theme.spacing()};
`;

export const DestinationsTitleContainer = styled.div`
  ${media.desktop`
    width:70%;
    margin-left: 2%;
  `}

`

export const SliderContainer = styled.div`
  width:30%;
  display:flex;
  justify-content: space-evenly;
  margin-top: 30px;

  ${media.tablet`
  margin-left: auto;
  margin-right: auto;
  `}

  ${media.mobile`
  margin-left: auto;
  margin-right: auto;
 `}

  ${media.desktop`
    margin-left: 1%;
  `}
`;

const ArrowContainer = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  background: #ffa24b;
  padding: 10px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
`;

const Arrow = styled.img`
  width:20px;
  height:20px;
`
const DiscoverDestinations = ({cities, country}) => {
  const isMobileOrTablet = useMedia('(max-width: 1000px)');
  const {t} = useTranslation();
  const [slider,setSlider] = useState(undefined);
  return (
    <ContainerSection>
      <Grid>
      <Cell sizes={{ default: 1, desktop: 1/3 }}>
        <TitleContainer>
          <DestinationsTitleContainer>
            <Title>
              {t('country.titleDiscover', {
                destination: country.title
            })}
            </Title>
          </DestinationsTitleContainer>
        </TitleContainer>
          <SliderContainer>
            <ArrowContainer onClick={()=> {if (slider){slider.slickPrev()}}}>
              <Arrow src={LeftArrowSlider}>

              </Arrow>
            </ArrowContainer>
            <ArrowContainer onClick={()=> {if (slider){slider.slickNext()}}}>
              <Arrow src={RightArrowSlider}>

              </Arrow>
            </ArrowContainer>
          </SliderContainer>
        </Cell>
        <Cell sizes={{ default: 1, desktop: 2/3 }}>
          <CityCards
            isCarousel={isMobileOrTablet}
            cities={cities}
            externalArrows
            setSlider={setSlider}
          />
        </Cell>
      </Grid>
    </ContainerSection>
  );
};

export default DiscoverDestinations;
